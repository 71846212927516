import React from "react";
import { Link } from "gatsby";
import Layout from "../layouts/index";
import { H1, P } from "../components/Type";
import { Container, Section } from "../components/LayoutUtils";
import SEO from "../components/SEO";
import Gallery from "../components/GalleryItemList";

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="Not found"
      description="This is not one of our handmade bespoke tailored suits, sorry"
    />
    <Container>
      <Section>
        <H1>Not found</H1>
        <P>
          We couldn&apos;t find that page, sorry! Perhaps you would like to read
          more about the <Link to="process">bespoke process</Link> or our{" "}
          <Link to="cloths">cloth suppliers</Link>.
        </P>
        <P>Failing that, enjoy the gallery below:</P>
      </Section>
      <Section>
        <Gallery navigate="false" />
      </Section>
    </Container>
  </Layout>
);

export default NotFoundPage;
